import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"
import {
  Editor,
  EditorState,
  CompositeDecorator,
  convertFromRaw,
  DefaultDraftBlockRenderMap,
} from "draft-js"
// importing draft.css in Layout.js
import styled, { ThemeContext } from "styled-components"
import css from "../../lib/styled-system/css"
import Image from "../utils/Image"
import { Map } from "immutable"

import Link from "../utils/Link"
import Box from "../atoms/Box"
import { Paragraph, Title, Text } from "../atoms/Typography"

const Wrapper = styled.div`
  ${props =>
    css({
      color: "text",
    })}
  .RichEditor-blockquote {
    ${props =>
      css({
        backgroundColor: props.bg,
      })}
    font-size: 14px;
    padding: 36px 40px;
    margin: 44px 24px;
    .public-DraftStyleDefault-block {
      margin-top: 0px;
    }
    @media (max-width: 640px) {
      margin-left: 12px;
      margin-right: 12px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  a {
    font-weight: inherit;
  }
  figure {
    margin: 0;
  }
  .RichEditor-code {
    font-family: "Courier", "Courier New", monospace;
    ${props =>
      css({
        backgroundColor: props.bg,
      })}
    font-size: 14px;
    padding: 36px 40px;
    margin: 44px 24px;
    .public-DraftStyleDefault-block {
      margin-top: 0px;
    }
    @media (max-width: 640px) {
      margin-left: 12px;
      margin-right: 12px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .RichEditor-unordered-list-item,
  .RichEditor-ordered-list-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .RichEditor-unstyled {
    margin-bottom: 16px;
  }
`

const RichText = props => {
  const {
    content,
    images,
    section,
    isArticle,
    textAlign,
    fontSize,
    ...rest
  } = props
  const [editorState, setEditorState] = useState(null)

  // ======================
  // BEGIN DECORATORS (image and links)
  // colors will probably be implemented using a decorator
  // ======================
  const ImageHandler = props => {
    const { id } = props.contentState.getEntity(props.entityKey).getData()
    const arrayOfSingleImage = images.filter(
      image => image.id.toString() === id.toString()
    )
    if (arrayOfSingleImage.length > 0) {
      const image = arrayOfSingleImage[0]
      return (
        <Box width="100%" my="44px">
          <Image {...image} />
        </Box>
      )
    }
    return <div>Image has been removed</div>
  }

  function findImageEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      if (entityKey === null) {
        return false
      }
      const entityType = contentState.getEntity(entityKey).getType()
      return entityType === "IMAGE"
    }, callback)
  }

  const LinkHandler = props => {
    const [hover, setHover] = useState(false)

    const linkData = props.contentState.getEntity(props.entityKey).getData()
    return (
      //Link requires https:// or http:// included in data, should we provide some kind of validation?
      <Link
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...linkData}
      >
        <Text
          style={{ transition: "opacity 0.1s ease" }}
          fontWeight="inherit"
          variant=""
          color="primary"
          opacity={hover ? 0.7 : 1}
        >
          {props.children}
        </Text>
      </Link>
    )
  }

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      if (entityKey === null) {
        return false
      }
      const entityType = contentState.getEntity(entityKey).getType()
      return entityType === "LINK"
    }, callback)
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findImageEntities,
      component: ImageHandler,
    },
    {
      strategy: findLinkEntities,
      component: LinkHandler,
    },
  ])
  // ======================
  // END DECORATORS
  // ======================

  // we need this useEffect to update the preview in Morphic
  useEffect(() => {
    setEditorState(
      content
        ? EditorState.createWithContent(convertFromRaw(content), decorator)
        : EditorState.createEmpty(decorator)
    )
  }, [content, images])

  // get blockquote/code background color (first bg color that doesn't match bg of richtext)

  // get current section theme
  let currentTheme = useContext(ThemeContext)
  let theme = "light"
  let bg = 0
  if (section) {
    theme = section.theme
    bg = section.bg
    if (theme === "default") theme = "light"
  }
  let blockQuoteBg = 0
  let themeBgColors = currentTheme.themes[theme].colors.background
  // loop over bg colors and get first one that doesn't match
  for (let i = 0; i < themeBgColors.length; i++) {
    if (themeBgColors[i] !== themeBgColors[bg]) {
      blockQuoteBg = i
      break
    }
  }

  // BLOCK STYLING
  // Two options for adding styles to blocks:

  // 1. we use block style function when it's not necessary to
  // wrap with custom component
  function getBlockStyle(block) {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote"
      case "code-block":
        return "RichEditor-code"
      case "unordered-list-item":
        return "RichEditor-unordered-list-item"
      case "ordered-list-item":
        return "RichEditor-ordered-list-item"
      case "paragraph":
      case "unstyled":
        return "RichEditor-unstyled"
      default:
        return null
    }
  }

  // 2. use block render map when you need to wrap with custom
  // component (usually typography)
  const blockRenderMap = Map({
    "header-one": {
      element: "div",
      wrapper: <Title variant="h1" mb={5} />,
    },
    "header-two": {
      element: "div",
      wrapper: <Title variant="h2" mb={5} />,
    },
    "header-three": {
      element: "div",
      wrapper: <Title variant="h3" mb={5} />,
    },
    subtitle: {
      element: "div",
      wrapper: <Paragraph fontSize={"smaller"} mb={4} />,
    },
  })

  // this applies the normal rendering process (including any decorators)
  // within custom blockRenderMap (necessary to support things like links and inline-styles
  // in headings and subtitles)
  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  )

  if (!editorState?.getCurrentContent()?.hasText()) return null

  return (
    <Box width={"100%"} {...rest}>
      <Wrapper textAlign={textAlign} bg={"background." + blockQuoteBg}>
        <Paragraph fontSize={fontSize} as={isArticle ? "article" : "div"}>
          <Editor
            textAlignment={textAlign}
            blockRenderMap={extendedBlockRenderMap}
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            contentEditable={false}
            readOnly
          />
        </Paragraph>
      </Wrapper>
    </Box>
  )
}

export default React.memo(RichText)

export const query = graphql`
  fragment RichText on Strapi_ComponentAtomsRichText {
    content
    images {
      id
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 703, layout: CONSTRAINED)
        }
      }
    }
  }
`
