import styled from "styled-components"
import { svg } from "../../lib/styled-system/system"
import { shouldForwardProp } from "../../lib/styled-system/helpers.js"
import { compose, color, background } from "styled-system"

const Path = styled("path").withConfig({ shouldForwardProp })`
  ${svg}
  ${compose(color, background)}
`

export default Path
