import React from "react"
import propTypes from "@styled-system/prop-types"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "../utils/Link"
import Box from "../atoms/Box"

const Img = styled("img")`
  height: 18px;
  margin-right: 9px;
`

const Span = styled("span")`
  font-family: Montserrat;
  font-size: 12px;
  color: #000000;
`

const MorphicBadge = ({ morphicBadgeTier, ...rest }) => {
  return (
    <Link href="https://getmorphic.com" {...rest}>
      <Box
        display="inline-flex"
        justifyContent="flex-start"
        alignItems="center"
        bg="white"
        height="32px"
        py="7px"
        pl="9px"
        pr="16px"
        borderRadius="7px"
        boxShadow="-2px 4px 10px rgba(0, 0, 0, 0.1)"
      >
        <Img src="/favicon-12.7.2021.png" alt="Morphic Logo" />
        <Span>
          {morphicBadgeTier === "enterprise"
            ? "Morphic Enterprise"
            : "Created with Morphic"}
        </Span>
      </Box>
    </Link>
  )
}

MorphicBadge.propTypes = {
  morphicBadgeTier: PropTypes.oneOf(["morphic", "enterprise"]),
  ...propTypes.space,
  ...propTypes.layout,
}

export default MorphicBadge
