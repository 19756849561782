import React, { useContext } from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled, { ThemeContext } from "styled-components"
import {
  system,
  typography,
  space,
  color,
  layout,
  compose,
} from "styled-system"
import variant from "../../lib/styled-system/variant"
import css from "../../lib/styled-system/css"
import { shouldForwardProp } from "../../lib/styled-system/helpers.js"
import { defaultTypographyVariants } from "../../lib/styled-system/constants"
import { animation } from "../../lib/styled-system/system"

const Typography = styled("span").withConfig({
  shouldForwardProp,
  displayName: "Typography",
})`
  margin: 0;
  white-space: pre-line;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : "none"};
  ${props =>
    css({
      fontWeight: "regular",
      color: props.color || "text",
      a: {
        color: "primary",
      },
      "&:hover": {
        ...props.hover,
      },
    })}
  ${variant({
    scale: "typography",
    variants: defaultTypographyVariants,
  })}
    ${compose(
      system({ textDecoration: true }),
      typography,
      space,
      color,
      layout,
      animation
    )};
`

/**
 * Title
 */
export const Title = ({ children, variant, as, ...rest }) => {
  const theme = useContext(ThemeContext)
  const fontSize = theme.typography[variant]?.fontSize
  return (
    <Typography
      variant={variant}
      fontSize={variant === "h3" ? fontSize : { _: fontSize - 1, sm: fontSize }}
      as={as ? as : variant}
      {...rest}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = {
  variant: PropTypes.oneOf(["h1", "h2", "h3"]).isRequired,
  as: PropTypes.oneOf(["h1", "h2", "h3", "span"]),
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Title.defaultProps = {
  variant: "h2",
}

/**
 * Paragraph
 */
export const Paragraph = ({ children, ...rest }) => {
  return (
    <Typography as="p" variant="p" {...rest}>
      {children}
    </Typography>
  )
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Label
 */
export const Label = ({ children, ...rest }) => {
  return (
    <Typography as="div" variant="label" {...rest}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Text
 */
export const Text = ({ children, variant = "text", hover, ...rest }) => {
  const theme = useContext(ThemeContext)
  const typography = theme?.typography?.[variant]
  const fontVariantHover = typography?.["& :hover"]
  return (
    <Typography
      as="span"
      color="inherit"
      variant="text"
      {...typography}
      hover={
        hover ? hover : fontVariantHover ? fontVariantHover : { opacity: 0.7 }
      }
      {...rest}
    >
      {children}
    </Typography>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}
