import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import FooterMinimal from "../footers/FooterMinimal"
import FooterMinimalSlide from "../footers/FooterMinimalSlide"
import FooterDefault from "../footers/FooterDefault"

const Footers = ({ site, lastSectionStyling, ...rest }) => {
  const footer = site.footer[0]
  if (site && site?.pages?.[0]?.hideFooter === "yes") {
    return null
  }

  switch (footer.__typename || footer.__component) {
    case "Strapi_ComponentFootersMinimal":
    case "footers.minimal":
      return (
        <FooterMinimal
          lastSectionStyling={lastSectionStyling}
          {...footer}
          {...rest}
        />
      )
    case "Strapi_ComponentFootersMinimalSlide":
    case "footers.minimal-slide":
      return (
        <FooterMinimalSlide
          lastSectionStyling={lastSectionStyling}
          {...footer}
        />
      )
    case "Strapi_ComponentFootersDefault":
    case "footers.default":
      return (
        <FooterDefault
          lastSectionStyling={lastSectionStyling}
          {...footer}
          {...rest}
        />
      )
    default:
      return null
  }
}

export default Footers

Footers.propTypes = {
  site: PropTypes.object.isRequired,
}

export var query = graphql`
  fragment Footers on Strapi_SiteFooterDynamicZone {
    __typename
    ... on Strapi_ComponentFootersMinimal {
      ...FooterMinimal
    }
    ... on Strapi_ComponentFootersDefault {
      ...FooterDefault
    }
    ... on Strapi_ComponentFootersMinimalSlide {
      ...FooterMinimalSlide
    }
  }
`
