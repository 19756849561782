import React, { useState } from "react"
import PropTypes from "prop-types"
import { splitProps } from "../../lib/styled-system/helpers"

import Box from "../atoms/Box"
import NavLink from "../atoms/NavLink"
import { Text } from "../atoms/Typography"

const NavDropdownMobile = ({ name, links, onDismiss, ...rest }) => {
  const [open, setOpen] = useState(false)
  const { typography, rest: boxProps } = splitProps(rest, "typography")
  return (
    <>
      <Box {...boxProps}>
        <button onClick={() => setOpen(!open)}>
          <Text {...typography}>{name}</Text>
        </button>
      </Box>
      {links.map((link, i) => (
        <Box mt={5} display={open ? "block" : "none"}>
          <NavLink
            fontSize={2}
            textAlign="center"
            partiallyActive={true}
            onClick={onDismiss}
            key={i}
            variant="navLink"
            {...link}
          />
        </Box>
      ))}
    </>
  )
}

NavDropdownMobile.strapiProps = {
  name: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(NavLink.strapiProps)),
}

NavDropdownMobile.propTypes = {
  onDismiss: PropTypes.func,
  ...NavDropdownMobile.strapiProps,
  ...Box.propTypes,
}

export default NavDropdownMobile
