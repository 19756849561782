import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import Button from "../atoms/Button"
import RichText from "../atoms/RichText"

const CookiePopup = ({ site, bg, theme, button, richText }) => {
  let enableCookiePopup = site?.cookiePopup[0]?.enableCookiePopup
  let popupStyle = site?.cookiePopup[0]?.popupStyle
  const [hidePopup, setHidePopup] = useState(null)
  const handleCloseCookiePopup = () => {
    localStorage.setItem("morphicCookiePopupClosed", true)
    setHidePopup("true")
  }

  useEffect(() => {
    setHidePopup(localStorage.getItem("morphicCookiePopupClosed"))
  }, [])

  return (
    <>
      {enableCookiePopup === true && hidePopup !== "true" && (
        <Flex
          justifyContent={popupStyle === "rightbox" ? "flex-end" : "flex-start"}
          width={"100%"}
          position={"fixed"}
          zIndex={1000}
          left={popupStyle === "leftbox" && { _: 2, sm: 4 }}
          right={popupStyle === "rightbox" && { _: 2, sm: 4 }}
          bottom={
            popupStyle === "leftbox" || popupStyle === "rightbox"
              ? { _: 2, sm: 4 }
              : 0
          }
        >
          <Box
            maxWidth={
              popupStyle === "leftbox" || popupStyle === "rightbox"
                ? 36
                : undefined
            }
            width={
              popupStyle !== "leftbox" && popupStyle !== "rightbox" && "100%"
            }
          >
            <Section
              theme={site?.cookiePopup[0]?.theme}
              bg={site?.cookiePopup[0]?.bg}
              verticalPadding={popupStyle === "fullwidth" ? "sm" : "md"}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                flexDirection={{ _: "column", md: "row" }}
              >
                <Box
                  maxWidth={
                    popupStyle === "leftbox" || popupStyle === "rightbox"
                      ? { _: "100%", xs: 20, sm: 44 }
                      : "100%"
                  }
                  mr={5}
                >
                  <RichText
                    {...site?.cookiePopup[0]?.richText}
                    fontSize={{ _: "12px", md: "inherit" }}
                  />
                </Box>
                <Button
                  {...site?.cookiePopup[0]?.button}
                  onClick={() => handleCloseCookiePopup()}
                />
              </Flex>
            </Section>
          </Box>
        </Flex>
      )}
    </>
  )
}

export default CookiePopup

export const query = graphql`
  fragment CookiePopup on Strapi_ComponentMoleculesCookiePopup {
    theme
    bg
    button {
      ...Button
    }
    richText {
      ...RichText
    }
    enableCookiePopup
    popupStyle
  }
`
