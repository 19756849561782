// Default constants that can be overwritten by the theme

// Breakpoint definitions - https://styled-system.com/responsive-styles
const breakpoints = ["425px", "640px", "768px", "1024px", "1280px"]
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

// Margin and padding values - https://styled-system.com/api#space
const space = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  16, // 4
  24, // 5
  32, // 6
  48, // 7
  64, // 8
  80, // 9
  96, // 10
  112, // 11
  128, // 12
  256, // 13
  512, // 14
]

// Height and width - https://styled-system.com/api#layout
// prettier-ignore
const sizes = [
  0, 16, 32, 48, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240, 
  256, 272, 288, 304, 320, 336, 352, 368, 384, 400, 416, 432, 448, 464, 
  480, 496, 512, 528, 544, 560, 576, 592, 608, 624, 640, 656, 672, 688, 
  704, 720, 736, 752, 768, 784, 800, 816, 832, 848, 864, 880, 896, 912, 
  928, 944, 960, 976, 992, 1008, 1024, 1040, 1056, 1072, 1088, 1104, 1120,
  1136, 1152, 1168, 1184, 1200, 1216, 1232, 1248, 1264, 1280, 1296
]

export const defaultButtonVariants = {
  primary: {
    height: "48px",
    minWidth: "172px",
    color: "textOnPrimary",
    bg: "primary",
    transitionProperty: "opacity",
    "&:hover": {
      opacity: 0.7,
    },
  },
  secondary: {
    height: "48px",
    minWidth: "172px",
    color: "primary",
    bg: "transparent",
    borderColor: "primary",
    borderWidth: "1px",
    borderStyle: "solid",
    transitionProperty: "opacity",
    "&:hover": {
      opacity: 0.7,
    },
  },
  link: {
    color: "primary",
    transitionProperty: "opacity",
    "&:hover": {
      opacity: 0.7,
      svg: {
        transform: "translateX(5px)",
      },
    },
  },
}

// below sequence is used to save fontsize values on strapi theme field
export const defaultTypographyVariants = {
  label: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "medium",
    fontSize: 0,
    textTransform: "uppercase",
  },
  p: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "regular",
    fontSize: 1,
  },
  button: {
    fontFamily: "body",
    fontWeight: "medium",
    fontSize: 2,
  },
  text: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "medium",
    fontSize: 3,
  },
  h3: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "medium",
    fontSize: 4,
  },
  h2: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "regular",
    fontSize: 5,
  },
  h1: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "regular",
    fontSize: 6,
  },
  navLink: {
    fontFamily: "body",
    fontWeight: "medium",
    fontSize: 7,
  },
}

// Default colors to use in the theme.
const colors = {
  white: "#fff",
  gray: "#f6f7fd",
  primary: "#4462ff",
  dark: "#37373d",
  black: "#000",
  shadow: "rgba(0,0,0,0.1)",
  borderLight: "rgba(0,0,0,0.1)",
  borderDark: "rgba(0,0,0,0.2)",
}

//Somewhat based on the theme specification of Theme UI
//https://theme-ui.com/theme-spec
export const defaultTheme = {
  colors: {
    text: colors.black,
    background: [colors.white, colors.gray],
    primary: colors.primary,
    secondary: colors.dark,
    textOnPrimary: colors.white,
    border: [colors.borderLight, colors.borderDark],
  },
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Montserrat, sans-serif",
  },
  fontSizes: [11, 14, 16, 18, 24, 36, 48, 16],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: { heading: "1.3em", body: "1.4em" },
  typography: defaultTypographyVariants,
  radii: { sm: 6, md: 12, lg: 24 },
  shadows: {
    sm: `0 5px 10px ${colors.shadow}`,
    md: `0 5px 25px ${colors.shadow}`,
    lg: `0 5px 35px ${colors.shadow}`,
  },
  buttons: defaultButtonVariants,
  space,
  sizes,
  breakpoints,
  zIndices: {
    header: 50,
    mobileMenu: 60, // max z-index should be 99
  },
  durations: { fast: 150, md: 500, slow: 1000 },
  timingFunctions: {
    ease: "ease",
    "ease-in": "ease-in",
    "ease-out": "ease-out",
    "ease-in-out": "ease-in-out",
  },
  reveal: { effect: "fade-up", duration: "slow", timingFunction: "ease" },
  themes: {
    dark: {
      colors: {
        primary: colors.primary,
        secondary: colors.white,
        text: colors.white,
        textOnPrimary: colors.white,
        background: [colors.dark, colors.dark],
      },
    },
    light: {
      colors: {
        primary: colors.primary,
        secondary: colors.dark,
        text: colors.black,
        textOnPrimary: colors.white,
        background: [colors.white, colors.gray],
      },
    },
    highlight: {
      colors: {
        primary: colors.white,
        secondary: colors.dark,
        text: colors.white,
        textOnPrimary: colors.primary,
        background: [colors.primary, colors.primary],
      },
    },
  },
}
