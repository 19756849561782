import React, { useContext } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled, { keyframes, ThemeContext } from "styled-components"
import {
  color,
  space,
  layout,
  typography,
  border,
  compose,
} from "styled-system"
import variant from "../../lib/styled-system/variant"
import css from "../../lib/styled-system/css"
import { splitProps, shouldForwardProp } from "../../lib/styled-system/helpers"
import { defaultButtonVariants } from "../../lib/styled-system/constants"

import Link from "../utils/Link"

import { Text } from "../atoms/Typography"
import Box from "../atoms/Box"

import SmallArrow from "../icons/SmallArrow"

const StyledButton = styled("button").withConfig({ shouldForwardProp })`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${css({
    fontWeight: "medium",
    borderRadius: "sm",
    px: 4,
    transitionDuration: "fast",
    transitionTimingFunction: "ease",
  })}
  ${variant({
    scale: "buttons",
    variants: defaultButtonVariants,
  })}
  ${compose(space, color, layout, typography, border)}
`
const load = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`
const LoadingBackground = styled("div")`
  position: absolute;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  animation-name: ${load};
  animation-iteration-count: infinite;
  ${css({ animationDuration: "slow", animationTimingFunction: "ease-out" })}
`

/*
 * Button component
 */
const Button = ({
  text,
  variant,
  link,
  children,
  loading,
  hideArrow,
  ...rest
}) => {
  // Split up space and typography props
  const { space, typography, rest: buttonProps } = splitProps(rest, [
    "space",
    "typography",
  ])
  const theme = useContext(ThemeContext)
  const svgColor =
    buttonProps.color || (theme?.buttons && theme?.buttons[variant]?.color)

  const linkVariant = variant === "link"
  if (linkVariant) {
    buttonProps.width = "auto"
    buttonProps.height = "auto"
    buttonProps.minWidth = "auto"
    buttonProps.px = 0
  }

  return (
    <Box {...space}>
      <Link {...link} optional>
        <StyledButton
          as={link ? "div" : "button"}
          variant={variant}
          {...buttonProps}
        >
          <Text {...typography} variant="button">
            {text || children}
          </Text>
          {linkVariant && !hideArrow && (
            <SmallArrow
              ml={3}
              color={svgColor}
              direction="right"
              transitionDuration="fast"
              transitionTimingFunction="ease-in-out"
              transitionProperty="transform"
            />
          )}
          {loading && <LoadingBackground />}
        </StyledButton>
      </Link>
    </Box>
  )
}

export default Button

Button.strapiProps = {
  variant: PropTypes.oneOf(["primary", "secondary", "link"]).isRequired,
  text: PropTypes.string,
  link: PropTypes.shape(Link.strapiProps),
}

Button.propTypes = {
  children: PropTypes.node,
  ...Button.strapiProps,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.border,
}

Button.defaultProps = {
  variant: "primary",
}

export const query = graphql`
  fragment Button on Strapi_ComponentAtomsButton {
    text
    variant
    link {
      ...Link
    }
  }
`
