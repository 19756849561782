import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { graphql, notGatsby } from "gatsby"
import styled from "styled-components"
import { shadow, color, compose, layout } from "styled-system"
import css from "../../lib/styled-system/css"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Link from "../utils/Link"
import Image from "../utils/Image"
import Reveal from "../utils/Reveal"
import HamburgerIcon from "../icons/Hamburger"

import StandardPadding from "../atoms/StandardPadding"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import NavLink from "../atoms/NavLink"
import Button from "../atoms/Button"
import ButtonElement from "../atoms/ButtonElement"
import { Title } from "../atoms/Typography"

import MobileMenu from "../molecules/MobileMenu"
import NavDropdown from "../molecules/NavDropdown"
import AnnouncementBar from "./AnnouncementBar"

export const StyledHeader = styled("header")(
  // Static styles
  { position: "relative" },
  // Static styles that are defined by the theme
  css({ height: 5, zIndex: "header" }),
  // Dynamic styles that can be changed by props
  compose(color, shadow, layout)
)

const HideNavBox = styled(Box)`
  transition: top 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
`
const defaultLogoSize = {
  sizeSelections: "fit",
  customSize: 200,
}

const HeaderDefault = ({
  theme = "default",
  bg = 0,
  shadow = "md",
  logo,
  siteTitle,
  links,
  button,
  button2,
  navAlignment,
  logoPosition,
  logoSize,
  headerWidth,
  dropDownOpenOn,
  dropdownPlacement,
  navBarDisplay,
  card,
  headerVisibility,
  announcementBar,
  site,
  isHomepage,
  firstSectionStyling,
  isTransparentNav = false,
}) => {
  headerVisibility = headerVisibility === null ? "visible" : headerVisibility
  logoSize = logoSize === null ? defaultLogoSize : logoSize
  dropdownPlacement = dropdownPlacement === null ? "center" : dropdownPlacement
  navBarDisplay = isTransparentNav ? "lock" : navBarDisplay // remove this line after adding nav background color for other navBarDisplay functionality

  if (isTransparentNav && firstSectionStyling) {
    theme = firstSectionStyling.theme
    bg = firstSectionStyling.bg
    shadow = "none"
  }
  const logoShift = logoPosition === "left" ? "left center" : "right center"
  const slug = site.pages && site.pages[0].slug

  const [menu, setMenu] = useState(false)

  const Logo = ({ logoSize, style }) => (
    <>
      {logo && (
        <Box
          flexShrink="0"
          width={{
            _: 8,
            md:
              logoSize?.sizeSelections === "custom"
                ? `${logoSize?.customSize}px`
                : 13,
          }}
          height={
            logoSize?.sizeSelections === "custom" ? "100%" : { _: 2, md: 3 }
          }
        >
          <Link
            to="/"
            display={
              logoSize?.sizeSelections === "custom" ? "contents" : "inline"
            }
          >
            {logo && (
              <Image
                {...logo}
                style={{ height: "100%" }}
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: logoShift,
                }}
              />
            )}
          </Link>
        </Box>
      )}
      {!logo && (
        <Link style={style} to="/">
          <Title variant="h1" as="span">
            {siteTitle}
          </Title>
        </Link>
      )}
    </>
  )

  const ConditionalPadding = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  //*SCROLL NAV APPEAR/DISAPPEAR LOGIC*
  const top = 0
  const [showNavTop, setShowNavTop] = useState(top)
  let scrollPos = 0
  const navRef = useRef(null)
  const [navHeight, setNavHeight] = useState(0)

  const scrollUpHandler = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > scrollPos && window.scrollY > navHeight / 2) {
      setShowNavTop(-navHeight)
    } else {
      setShowNavTop(top)
    }
    scrollPos = scrollTop
  }

  //grab nav height for scroll function
  useEffect(() => {
    if (navRef !== null && navRef.current !== null) {
      setNavHeight(navRef.current.getBoundingClientRect().height)
    }
  }, [])

  //scroll handler effect
  useEffect(() => {
    if (navBarDisplay === "appear") {
      document.addEventListener("scroll", scrollUpHandler)
    } else {
      document.removeEventListener("scroll", scrollUpHandler)
    }

    return () => {
      document.removeEventListener("scroll", scrollUpHandler)
    }
  }, [navHeight, navBarDisplay])

  return (
    <ThemeSwitcher theme={theme}>
      <AnnouncementBar
        {...announcementBar}
        slug={slug}
        isHomepage={isHomepage}
        menuOpen={menu}
      />
      <Box
        position={"relative"}
        height={navBarDisplay === "appear" ? `${navHeight}px` : 0}
        width={"100%"}
      />
      <HideNavBox
        ref={navRef}
        bg={isTransparentNav ? "transparent" : `background.${bg}`}
        zIndex="header"
        position={
          navBarDisplay === "scroll"
            ? "sticky"
            : navBarDisplay === "appear"
            ? "fixed"
            : "relative"
        }
        top={
          navBarDisplay === "scroll"
            ? 0
            : navBarDisplay === "appear"
            ? { showNavTop }
            : "inherit"
        }
        width={"100%"}
      >
        {/*this box is to keep the same header background while the header finish loading*/}
        <Reveal>
          <StyledHeader
            bg={isTransparentNav ? "transparent" : `background.${bg}`}
            boxShadow={shadow}
            display={headerVisibility === "hidden" ? "none" : "block"}
          >
            <ConditionalPadding
              condition={headerWidth !== "fullWidth"}
              wrapper={children => (
                <StandardPadding py={0} height="100%">
                  {children}
                </StandardPadding>
              )}
            >
              <Flex
                alignItems="center"
                flexDirection={logoPosition === "right" ? "row-reverse" : "row"}
                justifyContent={
                  navAlignment === "left"
                    ? { _: "space-between", lg: "flex-start" }
                    : { _: "space-between", lg: "center", xl: "space-between" }
                }
                height="100%"
                px={headerWidth === "fullWidth" && 5}
              >
                <Logo
                  logoSize={logoSize}
                  style={{
                    flex: logoPosition === "right" ? "0 0 auto" : "1 0 auto",
                  }}
                />
                <Box
                  flexDirection={
                    logoPosition === "right" ? "row-reverse" : "row"
                  }
                  flex="1 1 100%"
                  as="nav"
                  justifyContent={
                    (logoPosition === "left" && navAlignment === "right") ||
                    (logoPosition === "right" && navAlignment === "left")
                      ? "flex-end"
                      : "space-between"
                  }
                  alignItems="center"
                  display={{ _: "none", lg: "flex" }}
                  pl={
                    logoPosition === "left" && navAlignment === "left" ? 5 : 0
                  }
                  pr={
                    logoPosition === "right" && navAlignment === "right" ? 7 : 0
                  }
                >
                  <Flex>
                    {links.map((item, i) => {
                      switch (item.__typename || item.__component) {
                        case "Strapi_ComponentUtilsLink":
                        case "utils.link":
                          return (
                            <NavLink
                              ml={button && button2 ? { _: 4, xl: 6 } : 6}
                              partiallyActive={true}
                              key={i}
                              variant="navLink"
                              {...item}
                            />
                          )
                        case "Strapi_ComponentUtilsDropdown":
                        case "utils.dropdown":
                          return (
                            <NavDropdown
                              ml={6}
                              key={i}
                              {...item}
                              dropDownOpenOn={dropDownOpenOn}
                              dropdownPlacement={dropdownPlacement}
                              variant="navLink"
                              card={card}
                            />
                          )
                        default:
                          return null
                      }
                    })}
                  </Flex>
                  <Flex alignItems={"center"}>
                    {button && (
                      <Button
                        {...button}
                        ml={
                          button && button2
                            ? logoPosition === "left"
                              ? 6
                              : 0
                            : logoPosition === "left"
                            ? 6
                            : 0
                        }
                        mr={
                          button && button2
                            ? logoPosition === "right"
                              ? 6
                              : 0
                            : logoPosition === "right"
                            ? 6
                            : 0
                        }
                        hideArrow
                      />
                    )}
                    {button2 && (
                      <Button
                        {...button2}
                        ml={logoPosition === "left" ? 6 : 0}
                        mr={logoPosition === "right" ? 6 : 0}
                        hideArrow
                      />
                    )}
                  </Flex>
                </Box>
                <ButtonElement
                  onClick={() => setMenu(true)}
                  width={2}
                  height={2}
                  display={{ _: "block", lg: "none" }}
                >
                  <HamburgerIcon color="text" />
                </ButtonElement>
              </Flex>
            </ConditionalPadding>
          </StyledHeader>
        </Reveal>
      </HideNavBox>
      <MobileMenu
        isOpen={menu}
        onDismiss={() => setMenu(false)}
        links={links}
        logo={<Logo />}
        button={button}
        button2={button2}
        bg={`background.${bg}`}
      />
    </ThemeSwitcher>
  )
}

export default HeaderDefault

HeaderDefault.strapiProps = {
  logo: PropTypes.shape({
    url: PropTypes.string,
    alternativeText: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(NavLink.strapiProps),
      PropTypes.shape(NavDropdown.strapiProps),
    ])
  ),
  button: PropTypes.shape(Button.strapiProps),
  shadow: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]).isRequired,
  bg: PropTypes.number,
  headerVisibility: PropTypes.oneOf(["visible", "hidden"]),
  headerWidth: PropTypes.oneOf(["default", "fullWidth"]),
  navAlignment: PropTypes.oneOf(["left", "right"]),
  logoPosition: PropTypes.oneOf(["left", "right"]),
  dropDownOpenOn: PropTypes.oneOf(["click", "hover"]),
  dropdownPlacement: PropTypes.oneOf(["left", "center"]),
  navBarDisplay: PropTypes.oneOf(["scroll", "lock", "appear"]),
}

HeaderDefault.propTypes = {
  ...HeaderDefault.strapiProps,
  siteTitle: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment HeaderDefault on Strapi_ComponentHeadersDefault {
    __typename
    headerVisibility
    headerWidth
    theme
    bg
    shadow
    navAlignment
    logoPosition
    dropDownOpenOn
    dropdownPlacement
    logoSize {
      sizeSelections
      customSize
    }
    navBarDisplay
    announcementBar {
      bg
      homepageOnly
      lockedToTop
      shadow
      showAnnouncementBar
      text
      theme
      link {
        ...Link
      }
    }
    card {
      bg
      borderRadius
      boxShadow
      theme
    }
    logo {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 208
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
    links {
      __typename
      ... on Strapi_ComponentUtilsLink {
        ...Link
      }
      ... on Strapi_ComponentUtilsDropdown {
        name
        links {
          ...Link
        }
      }
    }
    button {
      ...Button
    }
    button2 {
      ...Button
    }
  }
`
