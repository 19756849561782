import React, { useContext } from "react"
import propTypes from "@styled-system/prop-types"
import styled, { ThemeContext } from "styled-components"
import { space, layout, compose } from "styled-system"
import css from "../../lib/styled-system/css"
// import { splitProps } from "../../lib/styled-system/helpers"

import Link from "../utils/Link"
import { Text } from "../atoms/Typography"

const StyledLink = styled(Link)`
  transition-property: opacity;

  ${props =>
    css({
      color: "text",
      transitionDuration: "fast",
      transitionTimingFunction: "ease",
      ...props.typography,
      "&.active": {
        color: "primary",
        ...props.typography["& :active"],
      },
    })}

  ${compose(space, layout)};
`

/**
 * NavLink - Used mainly for headers and footers
 */
const NavLink = ({ linkText, variant = "text", ...rest }) => {
  const theme = useContext(ThemeContext)
  const typography = theme?.typography?.[variant]
  return (
    <StyledLink typography={typography} {...rest}>
      <Text
        variant={variant}
        hover={
          typography?.["& :hover"] && variant === "navLink"
            ? { opacity: 1 }
            : { opacity: 0.7 }
        }
        color="inherit"
      >
        {linkText}
      </Text>
    </StyledLink>
  )
}

NavLink.strapiProps = {
  ...Link.strapiProps,
}

NavLink.propTypes = {
  ...NavLink.strapiProps,
  ...propTypes.space,
}

export default NavLink
